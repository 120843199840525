import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Fade,
  Flex,
  Input,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import axios, { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import ErrorMessage from "../../components/ErrorMessage";
import LoadingAnimation from "../../components/LoadingAnimation";
import SettingsLayout from "../../layouts/SettingsLayout";
import WarningMessage from "../../layouts/WarningMessage";
import GetPriceInfo from "../../queries/MetaDataFetcher";

const Settings = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const paymentSuccess = searchParams.get("success");
  const paymentCancelled = searchParams.get("cancelled");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [paymentInterval, setPaymentInterval] = useState<string>("year");
  const paymentURLQuery = useQuery("paymentUpdateLink", async () => {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}payments/createUpdatePaymentLink`,
      {
        token: localStorage.getItem("token"),
      }
    );
    return res.data.new_location;
  }, {
    cacheTime: 0
  })
  const addSubscription = useQuery("addSubscription", async () => {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}payments/subscription-addon`,
      {
        token: localStorage.getItem("token"),
        billingPeriod: paymentInterval,
        localUrl: "settings",
      }
    );

    return res?.data.new_location;
  }, {
    cacheTime: 0
  })

  const {
    isLoading: productsLoading,
    error: productsError,
    data: productsData,
  } = useQuery("priceData", GetPriceInfo);

  useEffect(() => {
    console.log("Changing subscription");
    addSubscription.refetch();
  }, [paymentInterval])

  const createUpdatePaymentLink = async () => {
    // const res = await axios.post(
    //   `${process.env.REACT_APP_BACKEND_URL}payments/createUpdatePaymentLink`,
    //   {
    //     token: localStorage.getItem("token"),
    //   }
    // );

    window.open(paymentURLQuery.data, "_blank");
  };

  const getCurrentAccount = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}accounts/currentAccount`,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    return res?.data;
  };

  const getBillingHistory = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}accounts/billingHistory`,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    return res?.data;
  };

  const getSubscriptionStatus = async () => {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}helper-subscription/status`,
      {},
      {
        headers: {
          userToken: localStorage.getItem("token"),
        },
      }
    );

    return res?.data;
  };

  const addChild = async () => {
    // const res = await axios.post(
    //   `${process.env.REACT_APP_BACKEND_URL}payments/subscription-addon`,
    //   {
    //     token: localStorage.getItem("token"),
    //     billingPeriod: paymentInterval,
    //     localUrl: "settings",
    //   }
    // );

    window.open(addSubscription.data);
  };

  const attemptRetryFailedPayment = async () => {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}payments/retry-failed-payment`,
      {
        token: localStorage.getItem("token"),
      }
    );
    return res?.data;
  };

  const {
    data: accountData,
    isLoading,
    isError,
  } = useQuery(["currentUser"], getCurrentAccount);

  const {
    data: billingData,
    isLoading: isBillingLoading,
    isError: isBillingError,
  } = useQuery(["getBillingHistory"], getBillingHistory);

  const {
    data: subscriptionData,
    isLoading: isSubscriptionLoading,
    isError: isSubscriptionError,
    refetch: refetchSubscriptionStatus,
  } = useQuery(["getSubscriptionStatus"], getSubscriptionStatus);

  const { mutate, isLoading: isAddChildLoading } = useMutation(addChild, {
    onError: (e: Error | AxiosError) => {
      return alert(e);
    },
  });

  const { mutate: retryFailedPayment, isLoading: isRetryFailedPaymentLoading } =
    useMutation(attemptRetryFailedPayment, {
      onSuccess: () => {
        refetchSubscriptionStatus();
      },
      onError: (e: Error | AxiosError) => {
        return alert(
          "There was an issue retrying your failed payment - please try again or contact support!"
        );
      },
    });

  useEffect(() => {
    if (paymentCancelled === "true" || paymentSuccess === "false") {
      return alert("Your payment was not completed, please try again.");
    }
  }, []);

  if (
    (!accountData ||
      isLoading ||
      isBillingLoading ||
      isSubscriptionLoading ||
      paymentURLQuery.isLoading ||
      isRetryFailedPaymentLoading) &&
    !isError &&
    !isBillingError &&
    !isSubscriptionError
  )
    return <LoadingAnimation />;

  if (isError) return <ErrorMessage />;

  if (!isSubscriptionLoading && subscriptionData.status === "inactive_notowned")
    return (
      <WarningMessage positive={false}>
        No subscription setup <br /> Please click below to setup subscription
        <Button
          variant="primary"
          mx="auto"
          mt="5"
          onClick={() => navigate("/signup/subscription")}
          fontFamily="gilroy"
          fontWeight="800"
        >
          Setup Subscription
        </Button>
      </WarningMessage>
    );

  if (!isSubscriptionLoading && subscriptionData.status === "inactive_owned")
    return (
      <WarningMessage positive={false}>
        Your subscription is not currently active
        <Text fontSize="18">
          Usually this is due to a payment failing, <br />
          please update your payment information before retrying!
        </Text>
        <Flex>
          <Button
            variant="accent"
            mx="auto"
            mt="5"
            onClick={createUpdatePaymentLink}
            fontFamily="gilroy"
            fontWeight="800"
          >
            Update Billing Info
          </Button>
          <Button
            variant="primary"
            mx="auto"
            mt="5"
            onClick={() => retryFailedPayment()}
            fontFamily="gilroy"
            fontWeight="800"
          >
            Retry Payment
          </Button>
        </Flex>
      </WarningMessage>
    );

  if (paymentSuccess) {
    return (
      <WarningMessage positive>
        Successfully added Child seat to your account
      </WarningMessage>
    );
  }

  return (
    <SettingsLayout>
      <Box
        display="flex"
        flexDir={["column", "row"]}
        gap="14"
        fontSize="3xl"
        mt="5"
        pb="10"
      >
        <Box>
          <Box>
            Account Details
            <Box display="flex" flexDir="column" gap="5" my="5" maxW="xl">
              <Input
                required
                type="email"
                value={accountData.account.email}
                disabled
                rounded="2xl"
              />
              <Input
                required
                type="password"
                value="************"
                disabled
                rounded="2xl"
              />
              <Text
                fontSize="sm"
                cursor="pointer"
                onClick={() => {
                  localStorage.clear();
                  window.location.href = "/request-reset-password";
                }}
                decoration="underline"
              >
                Reset Password?
              </Text>
            </Box>
          </Box>

          <Box mt="10">
            <Box display="flex" gap="5" alignItems="center">
              <Text>Subscription </Text>
              <Text
                ml="auto"
                size="sm"
                px="5"
                fontWeight="semibold"
                py="1.5"
                maxH="10"
                rounded="full"
                fontSize="md"
                color="white"
                backgroundColor={
                  subscriptionData.status === "active"
                    ? "shakumaku.green"
                    : "shakumaku.red"
                }
              >
                {subscriptionData.status === "active" ? "Active" : "Inactive"}
              </Text>
            </Box>

            {subscriptionData.status === "active" &&
            subscriptionData.paymentSource === "stripe" ? (
              <Box fontSize="md">
                <Box display="flex" alignItems="center" gap="3" mt="2">
                  {isAddChildLoading || productsLoading ? (
                    <LoadingAnimation />
                  ) : (
                    <Box w="full">
                      <Box
                        display="flex"
                        // size="sm"
                        cursor="pointer"
                        onClick={isOpen ? onClose : onOpen}
                      >
                        <Text as="span">Purchase Child Account</Text>
                        <Box ml="auto" px="2">
                          {isOpen ? (
                            <ChevronUpIcon w="7" ml="1" mr="-2" />
                          ) : (
                            <ChevronDownIcon w="7" ml="1" mr="-2" />
                          )}
                        </Box>
                      </Box>
                      {!isOpen && (
                        <Box mt="2">
                          <hr />
                        </Box>
                      )}

                      {isOpen && (
                        <Fade in={isOpen}>
                          <Box
                            mt="2"
                            borderColor="lightgrey"
                            borderRadius="3xl"
                            pb="5"
                            px="5"
                            pt="2"
                            borderWidth="1px"
                          >
                            <Box
                              display="flex"
                              flexDir="column"
                              gap="3"
                              color="white"
                              mt="3"
                            >
                              <Button
                                _hover={{ backgroundColor: "" }}
                                onClick={() => setPaymentInterval("year")}
                                py="12"
                                rounded="2xl"
                                backgroundColor={
                                  paymentInterval === "year"
                                    ? "shakumaku.darkBlue"
                                    : "lightgrey"
                                }
                                textAlign="left"
                              >
                                <Text ml="-19">
                                  {productsLoading ? (
                                    <>...</>
                                  ) : (
                                    <>
                                      {productsData?.year_display} Annual <br />
                                      ({productsData?.yearComponent} a month)
                                    </>
                                  )}
                                </Text>
                              </Button>
                              <Button
                                _hover={{ backgroundColor: "" }}
                                onClick={() => setPaymentInterval("month")}
                                py="12"
                                rounded="2xl"
                                backgroundColor={
                                  paymentInterval === "month"
                                    ? "shakumaku.darkBlue"
                                    : "lightgrey"
                                }
                                textAlign="left"
                              >
                                <Text ml="-19">
                                  {productsLoading ? (
                                    <>...</>
                                  ) : (
                                    <>
                                      {productsData?.month_display} Monthly{" "}
                                      <br />
                                    </>
                                  )}
                                </Text>
                              </Button>
                            </Box>
                            <Box display="flex" justifyContent="center">
                              <Button
                                disabled={addSubscription.isFetching}
                                variant={addSubscription.isFetching ? "grey" : "primary"}
                                mt="5"
                                px="6"
                                onClick={() => {
                                  if (addSubscription.isFetching) {
                                    return;
                                  } 
                                  mutate();
                                  onClose();
                                }}
                              >
                                Purchase
                              </Button>
                            </Box>
                          </Box>
                        </Fade>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            ) : (
              <Text fontSize="md" my="1" color="darkgrey" maxW="60">
                {subscriptionData.paymentSource === "stripe"
                  ? "Subscription information unavailable."
                  : "Manage your subscription from inside the Shakoo Maku App!"}
              </Text>
            )}
          </Box>
          <Box>
            <br />
            <br />
            <Button
              variant="red"
              onClick={() => {
                window.location.href = "/account-deletion";
              }}
            >
              Delete Account
            </Button>
          </Box>
        </Box>
        <Box /*  w="md" */>
          <Text>Billing</Text>
          {billingData?.length > 0 &&
          subscriptionData.paymentSource === "stripe" ? (
            <Box display="flex" flexDir="column">
              <Text fontSize="lg" mt="2">
                Latest Payment History
              </Text>
              <Box maxH="80" overflowY="auto">
                {billingData?.map((billingItem: any, i: number) => {
                  const date = new Date(billingItem.created * 1000);
                  const formattedDate = `${date.getDay()}/${
                    date.getMonth() + 1
                  }/${date.getFullYear()}`;

                  return (
                    <Box>
                      <Text fontSize="md" color="shakumaku.grey">
                        ${(billingItem.amount / 100).toFixed(2)} - Payment -{" "}
                        {formattedDate}{" "}
                      </Text>
                    </Box>
                  );
                })}
              </Box>
              <Button
                mt="7"
                variant="accent"
                onClick={createUpdatePaymentLink}
                mb="2"
                px="6"
                mx="auto"
              >
                Update Billing Info
              </Button>
            </Box>
          ) : (
            <Text fontSize="md" my="1" color="darkgrey">
              Billing information unavailable
            </Text>
          )}
        </Box>

        {/* <Box>Referral Code</Box> */}
      </Box>
    </SettingsLayout>
  );
};

export default Settings;
